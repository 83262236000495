import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { add, editIconY, uploaded, eyeView, linkUrl } from 'res/images';
import { Modal, Button, ButtonToolbar, Placeholder } from 'rsuite';
import PrescriptionActivityCreate from 'whealth-core-web/forms/PrescriptionActivityCreate';
import CoreButton from './CoreButton';
import { profileImage, dateFormat, dayTime, timeFormat, teamRoles, scrollToTop, splitArrData } from './Helper';
import Loader from './Loader';
import './prescription.css';
import { Pagination, Select2DropDown, EmptyView } from 'whealth-core-web/components';
import CommonToolTip from './CommonToolTip';
import { useDispatch } from 'react-redux';
import { setAlerts, setMedicineActiveCard, setReduxPrescriptionData } from 'redux/Slices';
import { ToastContainer, toast } from 'react-toastify';

function RenderPrescriptionTab(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { newPrescription, getPatientData } = props;
  const { id } = useParams();
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [departmentList, setDepartmentList] = useState([]);
  const [pdfModal, setPdfModule] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [printItem, setprintItem] = useState({});
  const [deepLink, setDeepLink] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    getListData();
  }, [id, currentPage]);
  const closeModalPdf = (item) => {
    setPdfModule(!pdfModal);
  };

  const openModalPdf = (item) => {
    setprintItem(item);
    if (!pdfModal) {
      setIsLoading(true);
      ApiClient.prescriptionPdfDownload(id, item.id)
        .then((res) => {
          setIsLoading(false);
          var blob = new Blob([res.data], { type: 'application/pdf' }); //this make the magic
          var blobURL = URL.createObjectURL(blob);
          setPdfUrl(blobURL);
          setPdfModule(!pdfModal);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = () => {
    ApiClient.getDepartment()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.warn('getDepartment error', err);
      });
  };

  const getListData = (departmentID = selectedDepartment, page = currentPage) => {
    setIsLoading(true);
    let params = { page };
    if (departmentID) {
      params.department_id = departmentID;
    }
    ApiClient.prescriptionList(id, params)
      .then((res) => {
        console.log('delete res', res)
        setIsLoading(false);
        dispatch(setMedicineActiveCard(res.data.filter((item) => item.status === 'published')));
        setTotalPages(res.headers['total-pages']);
        setPrescriptionData(res.data);
        dispatch(setReduxPrescriptionData(res.data.filter((item) => item.status === 'published')));
        scrollToTop();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const statusPublish = (item) => {
    setIsLoading(true);
    ApiClient.statusPublish(id, item.id)
      .then((res) => {
        setIsLoading(false);
        getListData();
        getPatientData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const statusUnpublished = (item) => {
    setIsLoading(true);
    ApiClient.statusUnpublished(id, item.id)
      .then((res) => {
        setIsLoading(false);
        getPatientData();
        getListData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const showDosageTimes = (arr) => {
    return arr.map((item, index) => {
      const objectKeys = Object.keys(item);
      return (
        <div key={index} className={`badge badge-pill me-3 ${item[objectKeys[1]]}`}>
          <img src={item[objectKeys[0]]} className='icon' /> {objectKeys[0]}
        </div>
      );
    });
  };

  const handleModalClose = () => {
    getListData();
    setOpen(false);
  };

  const handleClick = (id, pid) => {
    getPrescriptionsDeeplink(id, pid);
    toast.success('Link copied successfully', { autoClose: 2000 });
  };

  const getPrescriptionsDeeplink = (questionId) => {
    ApiClient.getPrescriptionDeeplink(id, questionId)
      .then((res) => {
        navigator.clipboard.writeText(res.data.deep_link);
        setDeepLink(res.data);
      })
      .catch((err) => {
        if (err.response.data.detail == 'Not found.') {
          setDeepLink('');
        }
      });
  };

  const customGoalActivityModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={() => handleModalClose()}>
          <Modal.Header />
          <PrescriptionActivityCreate handleSubmit={handleModalClose} setLoading={setIsLoading} data={open} />
        </Modal>
      </div>
    );
  };

  const renderListDataMedicine = useCallback(
    (medicinesData) => {
      return (
        medicinesData.prescription_medicines &&
        medicinesData.prescription_medicines.map((item, index) => {

          const productName = item?.product?.product_name || item?.medicine?.name
          const productCategory = item?.product?.category || item?.medicine?.medicine_category
          const lastUpdatedAt = item?.activity?.last_updated || item?.medicine?.updated_at
          return (

            <div key={index} className='p-2 mt-3 mileStone rounded'>
              <div className='d-flex justify-content-between '>
                <div
                  onClick={() => {
                    navigate(`/patient/${id}/prescription/${medicinesData.id}/medicines/${item.id}/show`);
                  }}
                  className='cardHeading pointer'
                >
                  <span className='semiBold capitalize'>{productName}</span>
                  <div className='capitalize'>{productCategory}</div>
                </div>
                <div className='d-flex'>
                  <div className='date me-2 '>
                    {item.activity && lastUpdatedAt
                      ? dateFormat(lastUpdatedAt) + ' · ' + timeFormat(lastUpdatedAt)
                      : 'No Activity'}
                  </div>
                  {/* <div className="d-flex justify-content-end addActivityBtn">
                    {medicinesData.status == "published" && (
                      <CommonToolTip
                        placement={"top"}
                        item={<img src={add} onClick={() => setOpen(item)} className="" />}
                        showValue={"Add Activity"}
                      />
                    )}
                  </div> */}
                </div>
              </div>
              {!item.is_optional ? (
                <div className='tagsHolder mt-2'>{showDosageTimes(dayTime(item.dosage))}</div>
              ) : (
                <div className='badge badge-light me-3 text-black mt-2'>As Needed</div>
              )}
              {!item.is_optional && (
                <div className='progressBar  mt-2 d-flex align-items-center'>
                  <div className='progress  w-100 flex-fill '>
                    <div
                      className='progress-bar '
                      role='progressbar'
                      aria-valuenow='0'
                      style={{
                        width: parseInt((item?.activity?.activity_number / item?.duration) * 100) + '%',
                      }}
                      aria-valuemin='0'
                      aria-valuemax={item?.activity?.activity_number}
                    ></div>
                  </div>
                  <div className='progress-value '>
                    {item?.activity && item?.activity?.activity_number ? item?.activity?.activity_number : '0'}/
                    {item?.duration} Days
                  </div>
                </div>
              )}
            </div>
          );
        })
      );
    },
    [prescriptionData]
  );

  const handlePublishAndPrint = () => {
    const windowConfirm = window.confirm(`Are you sure, you want to publish prescription?`);
    if (windowConfirm) {
      ApiClient.statusPublish(id, printItem.id)
        .then((res) => {
          setIsLoading(false);
          let iframe = document.createElement('iframe'); //load content in an iframe to print later
          document.body.appendChild(iframe);
          iframe.style.display = 'none';
          iframe.src = pdfUrl;
          iframe.onload = function() {
            setTimeout(function() {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
          getListData();
          getPatientData();
        })
        .catch((err) => {
          setIsLoading(false);
        });
      setPdfModule(false);
    }
  };

  const getPdf = (item) => {
    setIsLoading(true);
    ApiClient.prescriptionPdfDownload(id, item.id)
      .then((res) => {
        setIsLoading(false);
        var blob = new Blob([res.data], { type: 'application/pdf' }); //this make the magic
        var blobURL = URL.createObjectURL(blob);
        let iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const prescriptionDelete = (questionId) => {
    setIsLoading(true);
    // seterror({});
    ApiClient.deletePrescription(id, questionId)
      .then((res) => {
        setIsLoading(false);
        getListData();
        console.log(res);
        dispatch(setAlerts({ patient: { title: 'Prescription deleted successfully.', type: 'alert-success' } }));
      })
      .catch((err) => {
        // setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const renderMultiSelect = (data) => {
    let ansArr = splitArrData(data.answer, '|');
    return (
      <ul>
        {ansArr.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    );
  };

  const prescriptionDataObj = (data) => {
    if (data?.length) {
      let sortedData = [...data].sort(function(a, b) {
        return a.order - b.order;
      });
      return sortedData.map((item, i) => {
        return (
          <div className='d-flex'>
            <div className='w-100'>
              <span className='semiBold capitalize'>{item.label} · </span>
              <span className='prescriptiontime'>
                <pre className='zeefitness prefont_family firstletter_capitalize'>
                  {item.type == 'multi_select'
                    ? renderMultiSelect(item)
                    : item.type == 'date' && dateFormat(item.answer) != 'Invalid Date'
                      ? dateFormat(item.answer)
                      : item.answer}
                </pre>
              </span>
            </div>
          </div>
        );
      });
    }
  };

  const renderDepartmentDropdown = () => {
    return (
      <Select2DropDown
        placement='auto'
        value={selectedDepartment}
        data={departmentList}
        labelKey='name'
        // label={'Department'}
        placeholder={'Department'}
        valueKey='id'
        getObjData
        retuired
        onSelect={(_, selectedValue) => {
          setSelectedDepartment(selectedValue?.id || '');
          setCurrentPage(1);
          getListData(selectedValue?.id || '', 1);
        }}
        showMSG={''}
      />
    );
  };

  const renderPrescriptionList = useMemo(() => {
    return prescriptionData && prescriptionData.length > 0 ? (
      prescriptionData.map((item, index) => {
        const newText = item;

        const buttonsData = () => {
          return (
            <div className='headingMilestone d-flex justify-content-between'>
              <div className='d-flex'>
                <div>
                  <b>Assigned On &nbsp; &nbsp;</b>
                  {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
                </div>
                <div className='d-block  ms-2 '>
                  <span className={`badge bg-info  capitalize ${item.status}`}>{item.status}</span>
                </div>
              </div>

              <div
                className='memberShipDetails'
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                <>
                  <div className='mb-1 ms-2 pointer' style={{ color: '#fff', width: '25px' }}>
                    <CommonToolTip
                      placement={'top'}
                      item={
                        <img
                          onClick={() => handleClick(item.id)}
                          src={linkUrl}
                          alt='link'
                          className='icon'
                          style={{ cursor: 'pointer' }}
                        />
                      }
                      showValue={'Deeplink'}
                    />
                  </div>
                  {item.status == 'draft' && (
                    <div
                      className='mb-1 ms-2 pointer'
                      onClick={() => {
                        let windoConfirm = window.confirm('Are you sure you want to delete this prescription?');
                        if (windoConfirm) {
                          prescriptionDelete(item.id);
                        }
                      }}
                    >
                      <div className='pt-1'>
                        <CommonToolTip
                          placement={'top'}
                          item={<span class='material-icons-outlined'>delete_outline</span>}
                          showValue={'Delete'}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className='mb-1 ms-2 pointer'
                    onClick={() => navigate(`/patient/${id}/prescriptions/new`, { state: { id: item.id } })}
                  >
                    <CommonToolTip
                      placement={'top'}
                      item={
                        <div className='pt-1'>
                          <span className='material-icons-outlined' style={{ color: '#757575' }}>
                            content_copy
                          </span>
                        </div>
                      }
                      showValue={'Duplicate'}
                    />
                  </div>
                  {item.status == 'published' && (
                    <div className='mb-1 ms-2 pointer' onClick={() => getPdf(item)}>
                      <CommonToolTip placement={'top'} item={<img src={uploaded} />} showValue={'Download PDF'} />
                    </div>
                  )}
                  {item.status == 'draft' && (
                    <div
                      onClick={() => {
                        navigate(`/patient/${id}/prescriptions/${item.id}/edit`, { state: { id: item.id } });
                      }}
                      className='mb-2 ms-2 pointer'
                    >
                      <CommonToolTip
                        placement={'top'}
                        item={<img src={editIconY} className='icon' style={{ cursor: 'pointer' }} />}
                        showValue={'Edit'}
                      />
                    </div>
                  )}

                  {item.status !== 'published' ? (
                    <div
                      onClick={() => {
                        openModalPdf(item);
                      }}
                      className='ms-2 pointer'
                    >
                      <CommonToolTip
                        placement={'top'}
                        item={<span class='material-icons-outlined'>visibility</span>}
                        showValue={'View'}
                      />
                    </div>
                  ) : (
                    <CommonToolTip
                      placement={'top'}
                      item={
                        <div className='mb-2 ms-2 pointer'>
                          <div className='form-check form-switch toggleBtn pt-1 ps-4 ms-2'>
                            <input
                              className='form-check-input'
                              style={{ cursor: 'pointer' }}
                              checked={item.status == 'published'}
                              onChange={(e) => {
                                const windowConfirm = window.confirm(
                                  `Are you sure,you want to ${item.status == 'published' ? 'unpublish' : 'publish'
                                  } prescription?`
                                );
                                if (windowConfirm && item.status !== 'published') {
                                  statusPublish(item);
                                } else if (windowConfirm) {
                                  statusUnpublished(item);
                                }
                              }}
                              type='checkbox'
                              role='switch'
                            />
                          </div>
                        </div>
                      }
                      showValue={` ${item.status == 'published' ? 'Unpublish' : 'Publish'} `}
                    />
                  )}
                </>
              </div>
            </div>
          );
        };

        if (item?.department) {
          return (
            <div className='cardDataBorder'>
              <div key={index} className='d-flex'>
                <div className='w-100'>
                  <div className='cardMilestone'>
                    {buttonsData()}
                    <div className='d-flex mt-1 mb-3 justify-content-between align-items-center'>
                      <div className='d-flex'>
                        <div className='me-2'>
                          <img
                            className='rounded coachImage float-start img-fluid'
                            src={profileImage(item.admin.gender, item.admin.profile_url)}
                          />
                        </div>
                        <div className='w-75 '>
                          <h5 className='coachtext capitalize'>{item.admin.full_name}</h5>
                          <p className='zeefitness capitalize'>{teamRoles(item.admin.role)}</p>
                        </div>
                      </div>
                      <div>
                        {item?.department?.name ? (
                          <div class='badge capitalize lightGrayBadge'>Department {item?.department?.name}</div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      {item.doctor ? (
                        <div className='w-100 mb-2'>
                          <span className='semiBold capitalize'>
                            Doctor ·
                            <div>
                              <span className='prescriptiontime'>{item.doctor.full_name} </span>
                            </div>
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {item?.data && prescriptionDataObj(item?.data)}
                    {item?.enable_medicine && item?.prescription_medicines?.length ? (
                      <div className='d-flex'>
                        <div className='newMilestone warning w-100'>
                          <div className='badge badge-light me-3 text-black'>Medical Prescription</div>
                          {renderListDataMedicine(item)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className='cardDataBorder'>
              <div key={index} className='d-flex'>
                <div className='w-100'>
                  <div className='cardMilestone'>
                    {buttonsData()}
                    <div className='d-flex mt-1 mb-3'>
                      <div className='me-2'>
                        <img
                          className='rounded coachImage float-start img-fluid'
                          src={profileImage(item.admin.gender, item.admin.profile_url)}
                        />
                      </div>
                      <div className='w-75 '>
                        <h5 className='coachtext capitalize'>{item.admin.full_name}</h5>
                        <p className='zeefitness capitalize'>{teamRoles(item.admin.role)}</p>
                      </div>
                    </div>
                    <div>
                      {item.doctor ? (
                        <div className='w-100 mb-2'>
                          <span className='semiBold capitalize'>
                            Doctor ·
                            <div>
                              <span className='prescriptiontime'>{item.doctor.full_name} </span>
                            </div>
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div className='d-flex'>
                      <div className='w-100 mb-3'>
                        <span className='semiBold'>Diagnosis · </span>
                        <span className='prescriptiontime'>
                          {newText.diagnosis?.split('\n').map((line, index) => {
                            return (
                              <p className='firstletter_capitalize' key={index}>
                                {line}
                              </p>
                            );
                          })}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='newMilestone warning w-100'>
                        {item?.prescription_medicines?.length ? (
                          <div className='badge badge-light me-3 text-black'>Medical Prescription</div>
                        ) : null}
                        {renderListDataMedicine(item)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex mt-3'>
                <div className='w-100'>
                  <span className='semiBold '> Advice ·</span>
                  <span className='prescriptiontime'>
                    {newText.advice?.split('\n').map((line, index) => {
                      return (
                        <p className='firstletter_capitalize' key={index}>
                          {line}
                        </p>
                      );
                    })}
                  </span>
                </div>
              </div>
              <div className='d-flex mt-2'>
                <div className='w-100'>
                  <span className='semiBold'>Notes For Team .</span>
                  <span className='prescriptiontime'>
                    {newText.note?.split('\n').map((line, index) => {
                      return (
                        <p className='firstletter_capitalize' key={index}>
                          {line}
                        </p>
                      );
                    })}
                  </span>
                </div>
              </div>
            </div>
          );
        }
      })
    ) : (
      <EmptyView />
    );
  }, [prescriptionData]);

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='pt-3'>
          <button type='button' className='btn mt-3 btn-outline-primary' onClick={newPrescription}>
            <img src={add} className='icon' /> New Prescription
          </button>
        </div>
        {departmentList?.length && !props.hideDepartment ? (
          <div className='mt-3'>
            <div className='card-body'>{renderDepartmentDropdown()}</div>
          </div>
        ) : null}
      </div>

      {renderPrescriptionList}

      <Modal backdrop='static' size='lg' open={pdfModal} onClose={closeModalPdf}>
        <Modal.Header>
          <Modal.Title>Prescription View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfUrl + '#toolbar=0'}
            onContextMenu='return false;'
            width={'100%'}
            height={window.innerHeight}
          />
        </Modal.Body>
        <Modal.Footer className='mt-4'>
          <Button onClick={handlePublishAndPrint} appearance='primary'>
            Publish
          </Button>
          <Button onClick={closeModalPdf} appearance='defualt'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {customGoalActivityModal()}
      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default RenderPrescriptionTab;
