import React, { useEffect, useState } from 'react';
import { Box, TextField, MenuItem, Checkbox, FormControlLabel, Button, Typography } from '@mui/material';
import useApiManager from 'networking/ApiManager';
import { toast } from 'react-toastify';
import { Banknote, CopyCheck } from 'lucide-react';
const samplePayload = {
  name: 'Sanjay Kumar',
  amount: 300,
  phone: 7544998412,
  email: 'sanjay.kumar@revealhealthtech.com',
  payment_mode: 'cash',
  plan: 2,
  emi: 1,
  plan_items: [
    {
      product_name: 'cgm',
      product: 1453,
      quantity: 3,
    },
    {
      product_name: 'Doctor Consultation',
      product: 1454,
      quantity: 3,
    },
  ],
};

const initialValue = {
  plan: '',
  amount: '',
  name: '',
  phone: '',
  user_notes: '',
  admin_notes: '',
  emi: false,
};
const SellPlanForm = ({
  plans,
  setOpenRecordPaymentModal,
  setRecordPaymentPayload,
  currentSelectedPlan,
  setInvalidateAPIKey,
  setCurrentSelectedPlan,
  closeDrawer,
  openRecordPaymentModal,
}) => {
  const [paymentLink, setPaymentLink] = useState('');
  const ApiClient = useApiManager();
  const [formValues, setFormValues] = useState(initialValue);
  const currentPlan = plans.find((plan) => plan.id === currentSelectedPlan?.id);
  const [searchPlanKeyword, setSearchPlanKeyword] = useState('');
  const { searchPlanData } = useSearchPlans(searchPlanKeyword);
  const checkIfFormValuesIsEmpty = () => {
    if (formValues.plan === '' || formValues.amount === '' || formValues.name === '' || formValues.phone === '') {
      return true;
    } else {
      return false;
    }
  };
  const matchingPlan = searchPlanData?.find((plan) => {
    return plan.id === formValues.plan;
  });
  const isFormFilled = !checkIfFormValuesIsEmpty();
  const currentPrice = matchingPlan?.max_price;

  const minimumPrice = matchingPlan?.min_price;
  useEffect(() => {
    setSearchPlanKeyword(currentPlan?.name ?? '');
    setFormValues({
      plan: currentPlan?.id || '',
      amount: currentPlan?.max_price || '',
      name: '',
      phone: '',
      user_notes: '',
      admin_notes: '',
      emi: false,
    });
    return () => {
      setPaymentLink('');
    };
  }, [currentSelectedPlan]);
  const planOptions = [
    { title: 'Plan A', value: 'planA' },
    { title: 'Plan B', value: 'planB' },
  ];

  // const price = matchingPlan?.price

  const selectedPlanItems = searchPlanData
    ?.find((plan) => {
      return plan.id === formValues.plan;
    })
    ?.plan_items?.map((item) => ({
      id: item.id,
      product: item.product.id,
      quantity: item.quantity,
    }));

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'plan') {
      setCurrentSelectedPlan(plans.find((plan) => plan.id === value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Form Values:', formValues);
  };
  const createRazorPayPaymentReq = () => {
    const razorpayPayload = {
      name: formValues.name,
      amount: formValues.amount,
      phone: formValues.phone,
      payment_mode: 'razorpay',
      plan: formValues.plan,
      emi: formValues.emi ? 1 : 0,
      plan_items: selectedPlanItems,
      admin_notes: formValues.admin_notes,
      user_notes: formValues.user_notes,
    };
    ApiClient.createPlanPaymentRequest(razorpayPayload)
      .then((res) => {
        console.log('createRazorPayPaymentReq', res);
        setPaymentLink(res.data.payment_link);
      })
      .catch((err) => {
        const errData = err.response.data;
        console.log('errData', errData);
        Object.values(errData).map((errMsg) => toast.error(errMsg[0]));
        console.log('createRazorPayPaymentReq error', err);
      });
  };
  const handleCopyPaymentLink = () => {
    navigator.clipboard
      .writeText(paymentLink)
      .then(() => {
        console.log('payment lin', paymentLink);
        toast.success('Payment link copied to clipboard!');
        closeDrawer();
        setPaymentLink('');
        setFormValues(initialValue);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  return (
    <Box
      id="modal2-form"
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        mx: 'auto',
        p: 3,
        overflowY: 'scroll',
        height: '100%',
      }}
      noValidate={openRecordPaymentModal}
    >
      <Typography sx={{ mb: 3, textAlign: 'center', fontSize: '1rem' }}>Sell Plan</Typography>
      {/* Plan Details Section */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Plan Details
      </Typography>{' '}
      <Typography sx={{ mb: 2, fontSize: '1rem' }}>Plan Name</Typography>
      <SearchDropdown
        data={searchPlanData}
        searchPlanKeyword={searchPlanKeyword}
        setSearchKeyword={setSearchPlanKeyword}
        value={formValues.plan || ''}
        onChange={handleChange}
        label="Plan"
        setFormValues={setFormValues}
      />
      <TextField
        label="Price"
        name="amount"
        type="number"
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.amount}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: '0.875rem' }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: '0.75rem' },
        }}
      />
      {minimumPrice && <Typography sx={{ mb: 2 }}>Minimum - Rs. {minimumPrice}</Typography>}
      {/* Customer Details Section */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Customer Details
      </Typography>
      <TextField
        label="Name"
        name="name"
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.name}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: '0.875rem' }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: '0.75rem' },
        }}
      />
      <TextField
        label="Phone Number"
        name="phone"
        fullWidth
        margin="normal"
        variant="outlined"
        value={formValues.phone}
        onChange={handleChange}
        required
        InputProps={{
          sx: { fontSize: '0.875rem' }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: '0.75rem' },
        }}
      />
      <TextField
        label="Customer Facing Notes"
        name="user_notes"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        variant="outlined"
        value={formValues.user_notes}
        onChange={handleChange}
        InputProps={{
          sx: { fontSize: '0.875rem' }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: '0.75rem' },
        }}
      />
      <TextField
        label="Internal Notes"
        name="admin_notes"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        variant="outlined"
        value={formValues.admin_notes}
        onChange={handleChange}
        InputProps={{
          sx: { fontSize: '0.875rem' }, // Smaller font size and adjusted height
        }}
        InputLabelProps={{
          sx: { fontSize: '0.75rem' },
        }}
      />
      <FormControlLabel
        control={<Checkbox name="emi" checked={formValues.emi} onChange={handleChange} />}
        sx={{ fontSize: '0.75rem' }}
        label="Enable No Cost EMI"
      />
      {/* Action Buttons */}
      <Box sx={{ display: 'flex', gap: 2, mt: 3, justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ fontSize: '0.75rem' }}
          type="submit"
          onClick={() => {
            if (isFormFilled) {
              const recordPaymentPayload = {
                name: formValues.name,
                amount: formValues.amount,
                phone: formValues.phone,
                payment_mode: 'cash',
                plan: formValues.plan,
                emi: formValues.emi ? 1 : 0,
                plan_items: selectedPlanItems,
                admin_notes: formValues.admin_notes,
                user_notes: formValues.user_notes,
              };
              setRecordPaymentPayload(recordPaymentPayload);
              setOpenRecordPaymentModal(true);
              setFormValues(initialValue);
            } else {
              // toast.error('Please fill all the required fields');
            }
          }}
        >
          Record Payment
        </Button>
        {!paymentLink ? (
          <Button
            variant="outlined"
            color="primary"
            sx={{
              fontSize: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
            type="submit"
            onClick={() => {
              if (isFormFilled) {
                createRazorPayPaymentReq();
              } else {
                //toast.error('Please fill all the required fields');
              }
            }}
          >
            <Banknote />
            Generate Payment Link
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
            onClick={handleCopyPaymentLink}
          >
            <CopyCheck />
            Copy Payment Link
          </Button>
        )}
      </Box>
      {/* <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, fontSize: "0.75rem" }}
      >
        Submit
      </Button> */}
    </Box>
  );
};

export default SellPlanForm;

const SearchDropdown = ({ data, setSearchKeyword, setFormValues, value, label, searchPlanKeyword }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    const planName = data?.find((plan) => plan.id === value)?.name;
    setSearchKeyword(value);
    setIsDropdownVisible(true);
  };

  const handleOptionClick = ({ id, name, amount }) => {
    setSearchKeyword(name);
    setFormValues((formValues) => {
      return {
        ...formValues,
        amount: amount,
        plan: id,
      };
    });

    setIsDropdownVisible(false);
  };

  const containerStyle = {
    // width: "250px",
    margin: '0 auto',
    position: 'relative',
    fontFamily: 'Arial, sans-serif',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

  const dropdownStyle = {
    position: 'absolute',
    top: '40px',
    width: '100%',
    maxHeight: '150px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    zIndex: 1000,
  };

  const optionStyle = {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #f0f0f0',
  };

  const optionHoverStyle = {
    backgroundColor: '#f0f0f0',
  };

  return (
    <div style={containerStyle}>
      <input
        type="text"
        value={searchPlanKeyword}
        onChange={handleSearchChange}
        style={inputStyle}
        placeholder="Search Plan..."
      />
      {isDropdownVisible && data?.length > 0 && (
        <div style={dropdownStyle}>
          {data?.map((option, index) => {
            return (
              <div
                key={index}
                style={{
                  ...optionStyle,
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#fff')}
                onClick={() =>
                  handleOptionClick({
                    id: option.id,
                    name: option.name,
                    amount: option.max_price,
                  })
                }
              >
                {option.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const useSearchPlans = (searchTerm) => {
  const [searchPlanData, setSearchPlanData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ApiClient = useApiManager();
  useEffect(() => {
    setIsLoading(true);
    ApiClient.getAllPlans({
      page: 1,
      searchParam: searchTerm,
      status: 'published',
    })
      .then((res) => {
        const delta = Math.round(res.data.count % 10) > 0 ? 1 : 0;
        setSearchPlanData(res.data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('All plans API error', err);
        setIsLoading(false);
      });
  }, [searchTerm]);
  return { searchPlanData, isLoading };
};
