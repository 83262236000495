import { disable } from 'mixpanel-browser';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { use } from 'react';
import { useSelector } from 'react-redux';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { upload } from 'res/images';
import { DatePicker } from 'rsuite';
import {
  BorderBtnWithBg,
  CommonBorderBox,
  CoreButton,
  CoreInputBox,
  Select2DropDown,
  TagsShow,
} from 'whealth-core-web/components';
import CommonMaterialUiDatePicker from 'whealth-core-web/components/CommonMaterialUiDatePicker';
import { dateFormatStringSingleDate } from 'whealth-core-web/components/Helper';

function AddPatientForm(props) {
  const { createPatient, errors, patientData, getPatientData, id } = props;
  const inputRef = useRef();
  const ApiClient = useApiManager();
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const locations = useSelector((state) => state.sliceReducer.locations);
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [careManagersList, setCareManagersList] = useState([]);
  const [carePlanList, setPlanList] = useState([]);
  const [carePlanOnchangeId, setPlanOnchangeId] = useState('');
  const [isCarePlan, setIsCarePlan] = useState('');

  useEffect(() => {
    // Check if pincode exists and state is empty
    if (patientData?.pincode && !patientData?.state) {
      fetchCityAndState(patientData.pincode);
    }
  }, [patientData]);

  useEffect(() => {
    // Pre-fill the form with existing patient data
    setFormData(patientData);
  }, [patientData]);

  useEffect(() => {
    getPatientData();
    const getCareManagers = async () => {
      const res = await ApiClient.getCareManagers();
      let tempList = [];
      res.data.map((item, index) => {
        tempList.push({ ...item, title: item.full_name });
      });
      setCareManagersList(tempList);
    };

    const getCarePlans = async () => {
      const res = await ApiClient.getCarePlan();
      let tempList = [];
      res.data.map((item, index) => {
        tempList.push({ ...item, title: item.title });
      });
      setPlanList(tempList);
    };
    getCarePlans();
    getCareManagers();
  }, []);
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
        if (item.key == 'care_plan') {
          setIsCarePlan(item.isActive);
        }
      });
    }
  }, [configData]);
  useEffect(() => {
    setServerImage(patientData?.profile_url);
    setFormData(patientData);
  }, [patientData]);

  const fetchCityAndState = async (pincode) => {
    try {
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      const data = await response.json();
      if (data[0].Status === 'Success' && data[0].PostOffice && data[0].PostOffice.length > 0) {
        const { District, State } = data[0].PostOffice[0];
        setFormData((prevData) => ({
          ...prevData,
          city: District,
          state: State,
          pincode: pincode,
        }));
      } else {
        console.error('No records found for the entered pincode.');
        setFormData((prevData) => ({
          ...prevData,
          city: '',
          state: '',
          pincode: pincode,
        }));
      }
    } catch (error) {
      console.error('Error fetching city and state:', error);
    }
  };

  const handleChange = (key, val) => {
    if (key === 'pincode' && val.length === 6) {
      fetchCityAndState(val);
    } else {
      setFormData((prevData) => ({ ...prevData, [key]: val }));
    }

    if (key === 'care_plan_id') {
      setFormData((prevData) => ({
        ...prevData,
        [key]: val,
        c_plan_activation_date: dateFormatStringSingleDate(new Date()),
      }));
    }
  };

  const getCarePlanSearch = async (query) => {
    const res = await ApiClient.getCareSearch(query);
    if (res.data.status == 'active') {
      setPlanList(res.data);
    }
  };
  const genderData = [
    { title: 'Male', id: 'male' },
    { title: 'Female', id: 'female' },
    { title: 'Other', id: 'other' },
  ];
  const vendorListArr = [
    { title: 'Orange health', id: 'Orange health' },
    { title: 'Thyrocare', id: 'Thyrocare' },
    { title: 'Redcliffe labs', id: 'Redcliffe labs' },
    { title: 'Healthians', id: 'Healthians' },
  ];
  const preferredLanguages = [
    { title: 'English', id: 'English' },
    { title: 'Hindi', id: 'Hindi' },
    { title: 'Bengali', id: 'Bengali' },
    { title: 'Telugu', id: 'Telugu' },
    { title: 'Gujarati', id: 'Gujarati' },
    { title: 'Kannada', id: 'Kannada' },
    { title: 'Tamil', id: 'Tamil' },
    { title: 'Marathi', id: 'Marathi' },
    { title: 'Malayalam', id: 'Malayalam' },
    { title: 'Assamese', id: 'Assamese' },
    { title: 'Punjabi', id: 'Punjabi' },
    { title: 'Oriya', id: 'Oriya' },
    { title: 'Other', id: 'Other' },
  ];
  const planStatus = [
    { title: 'Active', id: 'Active' },
    { title: 'Inactive', id: 'Inactive' },
  ];
  const inputFieldsData = [
    {
      key: 'full_name',
      placeholder: 'Full Name',

      label: 'User Name',
      viewType: 'text',
      maxLength: 120,
      message: errors?.full_name,
      retuired: true,
    },
    {
      key: 'personality_type',
      placeholder: 'Personality Type',

      label: 'Personality Type',
      viewType: 'text',
      maxLength: 120,
      message: errors?.personality_type,
    },
    {
      key: 'gender',
      placeholder: 'Select Gender',
      label: 'Select Gender',
      viewType: 'dropDown',
      data: genderData,
      message: errors?.gender,
    },
    {
      key: 'date_of_birth',
      placeholder: 'Date Of Birth',
      label: 'Date Of Birth',
      viewType: 'date',
    },
    {
      key: 'preferred_language',
      placeholder: '',
      label: 'Preferred Language',
      viewType: 'text',
      message: errors?.preferred_language,
      disabled: true,
    },
    {
      key: 'phone',
      label: 'Mobile No.',
      placeholder: '10 Digit Mobile Number',
      viewType: 'text',
      retuired: true,
      maxLength: 10,
      validateNumbers: true,
      showCode: true,
      message: errors?.phone,
    },
    {
      key: 'email',
      placeholder: 'Email Address',
      label: 'Email Address',
      viewType: 'text',
      message: errors?.email,
    },

    {
      key: 'address',
      label: 'Residence address',
      placeholder: 'Residence Address',
      viewType: 'text',
      message: errors?.address,
      retuired: !!errors?.address,
    },

    {
      key: 'pincode',
      placeholder: 'Pincode',
      label: 'pincode',
      viewType: 'text',
      message: errors?.pincode,
      maxLength: 6,
      validateNumbers: true,
      retuired: !!errors?.pincode,
    },

    {
      key: 'city',
      placeholder: 'City',
      label: ' City',
      viewType: 'text',
      message: errors?.city,
      retuired: !!errors?.city,
      disabled: true,
    },

    {
      key: 'state',
      placeholder: 'State',
      label: 'state',
      viewType: 'text',
      message: errors?.state,
      maxLength: 50,
      retuired: !!errors?.state,
      disabled: true,
    },
    {
      key: 'ee_name',
      placeholder: 'ES Name',
      label: 'ES Name',
      viewType: 'text',
      message: errors?.es_name,
      maxLength: 50,
      retuired: !!errors?.state,
      disabled: true,
    },
    {
      key: 'medicine_cost_pitched',
      placeholder: 'Medicine Cost Pitched',
      label: 'Medicine Cost Pitched',
      viewType: 'text',
      message: errors?.med_cost_pitched,
      maxLength: 50,
      retuired: !!errors?.med_cost_pitched,
      disabled: true,
    },

    isNivanCare && {
      key: 'locations',
      placeholder: 'Select Clinic',
      label: 'Clinic',
      viewType: 'tags',
      data: locations,
      multiple: true,
      message: errors?.locations,
    },
    isNivanCare && {
      key: 'care_manager_id',
      placeholder: 'Select Care Managers',
      label: 'Care Manager',
      viewType: 'careDropDown',
      data: careManagersList,
      multiple: true,
      message: errors?.care_manager_id,
    },
    id && {
      key: 'plan_enrolled',
      placeholder: 'Select Plan',
      label: 'Plan Enrolled',
      viewType: 'text',
      // data: carePlanList,
      message: errors?.care_plan_id,
      disabled: true,
    },
    id && {
      key: 'vendor',
      placeholder: 'Vendor',
      label: 'Vendor',
      viewType: 'dropDown',
      data: vendorListArr,
      message: errors?.vendor,
    },
    id && {
      key: 'plan_status',
      placeholder: 'Plan Status',
      label: 'Plan Status',
      viewType: 'dropDown',
      data: planStatus,
      message: errors?.plan_status,
    },
    (formData?.care_plan_id || carePlanOnchangeId) &&
      isCarePlan && {
        key: 'c_plan_activation_date',
        placeholder: 'Workflow Activation Date',
        label: ' Workflow Activation Date',
        viewType: 'ActivationDate',
        userNotSelectData: 'pastDate',
      },
  ];

  const renderFormData = (showData) => {
    return showData.map((item, index) => {
      const showHTML = item?.html;
      delete item?.html;
      if (item?.viewType == 'text') {
        return (
          <div key={index} className="articleInputDiv d-lg-flex mt-3 " style={item?.singleView && { width: '100%' }}>
            <div className="w-100 d-flex ">
              <div className="w-100 ">
                <CoreInputBox
                  {...item}
                  type={item.viewType}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  setValue={(data) => handleChange(item.key, data)}
                  value={formData[item.key]}
                  showMSG={item.message}
                  msgStyle={{ color: 'red' }}
                />
              </div>
              {showHTML && showHTML()}
            </div>
          </div>
        );
      }
      if (item?.viewType == 'date') {
        return (
          <div className="mt-3 articleInputDiv">
            <div>
              <label class="inputlabel">{item.label}</label>
            </div>
            {/* <DatePicker
              disabledDate={(date) => {
                if (new Date(date) > new Date()) {
                  return true;
                } else {
                  return false;
                }
              }}
              onClean={() => {
                let newFormData = { ...formData, ['date_of_birth']: null };
                setFormData({ ...newFormData });
              }}
              style={{ width: '100%' }}
              placement='bottomStart'
              format='yyyy-MM-dd'
              placeholder='YYYY-MM-DD'
              value={formData[item.key] ? new Date(formData[item.key]) : null}
              calendarDefaultDate={formData[item.key] ? new Date(formData[item.key]) : null}
              onSelect={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
            /> */}
            <CommonMaterialUiDatePicker
              value={formData[item.key] ? formData[item.key] : null}
              onChange={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
              placeholder={'YYYY-MM-DD'}
            />
          </div>
        );
      } else if (item?.viewType == 'ActivationDate') {
        return (
          <div className="mt-3 articleInputDiv">
            <div>
              <label class="inputlabel">{item.label}</label>
            </div>
            <DatePicker
              disabledDate={(date) => {
                const today = new Date();
                const currentDate = new Date(date);
                return currentDate < today.setHours(0, 0, 0, 0);
              }}
              onClean={() => {
                let newFormData = { ...formData, ['c_plan_activation_date']: dateFormatStringSingleDate(new Date()) };
                setFormData({ ...newFormData });
              }}
              style={{ width: '100%' }}
              placement="bottomStart"
              format="yyyy-MM-dd"
              placeholder="YYYY-MM-DD"
              value={formData[item.key] ? new Date(formData[item.key]) : new Date()}
              calendarDefaultDate={formData[item.key] ? new Date(formData[item.key]) : new Date()}
              onSelect={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
            />
          </div>
        );
      } else if (item?.viewType == 'dropDown') {
        return (
          <div key={index} className=" articleInputDiv d-lg-flex mt-3" style={item.singleView && { width: '100%' }}>
            <div className="w-100">
              <Select2DropDown
                isValidation
                onSearch={(data) => getCarePlanSearch(data)}
                searchable={item.key !== 'gender' && true}
                data={
                  !item.data.find((menu) => formData.care_plan?.id == menu.id) && formData.care_plan?.id
                    ? [...item.data, formData?.care_plan]
                    : item.data
                }
                retuired={item.retuired}
                placeholder={item.placeholder}
                label={item.label}
                onSelect={(data, dataObj) => {
                  handleChange(item.key, data, item);
                }}
                value={formData[item.key] || ''}
                showMSG={item.message}
                msgStyle={{ color: 'red' }}
                valueKey={item.setKey}
              />
            </div>
          </div>
        );
      } else if (item?.viewType == 'tags') {
        return (
          <div key={index} className="articleInputDiv mt-3">
            <TagsShow
              details={item.detail}
              tagsStyle={item.tagsStyle}
              data={item.data}
              keepSearchTerm={true}
              retuired={item.retuired}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={item.placeholder}
              label={item.label}
              setValue={(data) => handleChange(item.key, data)}
              value={formData[item.key]}
              showMSG={item.message}
              msgStyle={{ color: 'red' }}
            />
          </div>
        );
      }
    });
  };

  return (
    <div>
      <CommonBorderBox className="d-flex justify-content-between teamBorderBox">
        <div className="d-flex teamAddForm" style={{ gap: '32px', justifyContent: 'space-between' }}>
          <div className="teamFormDiv">
            {/* <div className='semiBoldWeight'>Basic Info</div> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createPatient(formData);
              }}
            >
              {/* <div className='teamFormDiv'> */}
              {/* {renderFormData(inputFieldsData)} */}
              <div className="semiBoldWeight">Personal Details</div>
              <div className="teamFormDiv">{renderFormData(inputFieldsData.slice(0, 5))}</div>
              <div className="semiBoldWeight mt-3">Contact Details</div>
              <div className="teamFormDiv">{renderFormData(inputFieldsData.slice(5, 13))}</div>
              {id && (
                <>
                  <div className="semiBoldWeight mt-3">Plan Details</div>
                  <div className="teamFormDiv">{renderFormData(inputFieldsData.slice(13, 20))}</div>
                </>
              )}
              <CoreButton
                onClick={() => createPatient(formData)}
                type="submit"
                title="Save Details"
                customDivStyle={{ width: '100%' }}
              />
              {/* </div> */}
              <button type="submit" className="d-none"></button>
            </form>
          </div>
          <div>
            <div>
              <div className="profilePhotoStyle">
                <input
                  ref={inputRef}
                  type="file"
                  className="d-none"
                  value=""
                  accept="image/*"
                  onChange={(e) => {
                    let tmpData = { ...formData };
                    tmpData.profile_url = e.target.files[0];
                    setFormData({ ...tmpData });
                    setImage(e.target.files[0]);
                  }}
                />
                {image && <img src={URL.createObjectURL(image)} />}
                {serverImage && !image && <img src={serverImage} />}
                {!image && !serverImage && <div className="w-50">Drop Profile Photo</div>}
              </div>
            </div>
            <div>
              <BorderBtnWithBg
                onClick={() => {
                  inputRef.current.click();
                }}
                className="uploadPhotoBtn"
                iconImg={upload}
                title="Upload"
              />
            </div>
          </div>
        </div>
      </CommonBorderBox>
      <div></div>
    </div>
  );
}

export default AddPatientForm;