import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import { filters, orderDetails, orderDetailsBlue, patientDetails, search, viewOrder, viewOrderBlue } from 'res/images';
import { Dropdown, Modal } from 'rsuite';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, exportToCsv, timeFormat, titlizeString } from '../Helper';
import Loader from '../Loader';
import './Orders.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PatientDetails from './PatientDetails';
import OrderDetails from './OrderDetails';
import ViewOrder from './ViewOrder';
import { useSelector, useDispatch } from 'react-redux';
import {
  useCreateAnOrderMutation,
  useGetPrescriptionListQuery,
  useUpdateManualOrderMutation,
} from 'redux/dashboardApis';
import { setManualOrderId, setManualOrderIdStr, setFromViewOrderToOrderDetail } from 'redux/Slices';
import { downloadIcon } from 'whealth-core-web/assets/images';
import { ToastContainer, toast } from 'react-toastify';

function AddOrder() {
  const dispatch = useDispatch();
  const patientAddress = useSelector((state) => state?.sliceReducer?.patientAddress);
  const patientManualDetails = useSelector((state) => state?.sliceReducer?.patientManualDetails);
  const manualOrderItems = useSelector((state) => state?.sliceReducer?.manualOrderItems);
  const manualOrderSummary = useSelector((state) => state?.sliceReducer?.manualOrderSummary);
  const reduxNotesData = useSelector((state) => state?.sliceReducer?.reduxNotesData);
  const latestPrescription = useSelector((state) => state?.sliceReducer?.latestPrescription);
  const { orderId: urlOrderId, orderStatus: urlOrderStatus, patientId: urlPatientId, index } = useParams();
  const location = useLocation();
  const { orderStatus, patientName, orderId } = location.state || {};
  const [publishedPrescription, setPublishedPrescription] = useState();
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const [isNewMedicineAdded, setIsNewMedicineAdded] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [error, setError] = useState('');
  const [medicineErrors, setMedicineErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [newOrderId, setNewOrderId] = useState();
  const [currentOrderStatus, setCurrentOrderStatus] = useState('');
  const PatientAddressStep1 = useSelector((state) => state?.sliceReducer?.patientAddressStep1);
  const manualOrderId = useSelector((state) => state?.sliceReducer?.manualOrderId);
  let [createManualOrder, manualOrderResponseData] = useCreateAnOrderMutation();
  let [updateManualOrder, updateManualOrderResponseData] = useUpdateManualOrderMutation();
  const value = useRef('');
  const [activeTab, setActiveTab] = useState(orderStatus === 'draft' ? 'viewOrder' : 'patientDetails');
  const [prescriptionData, setPrescriptionData] = useState([]);
  const getPrescription = (id) => {
    ApiClient.prescriptionList(id)
      .then((res) => {
        setPrescriptionData(res?.data);
        if(res.data.length === 0) {
          toast.error('Prescription not found, please add a prescription', {
            position: 'top-right',
            //autoClose: 2000,
          });
        }
        const prescription = res?.data?.data?.find((item) => item.status === 'published');
        setPublishedPrescription(prescription);
      })
      .catch((err) => {
        console.error('Error fetching prescription: ', err);
      });
  };

  useEffect(() => {
    if (patientManualDetails?.id) {
      getPrescription(patientManualDetails?.id);
    }
  }, [patientManualDetails]);

  const checkManualOrderItems = (manualOrderItemsArray) => {
    if (manualOrderItemsArray.length === 0) {
      setMedicineErrors('Please select a variation for all medicines');
      return false;
    }
    for (let i = 0; i < manualOrderItemsArray.length; i++) {
      const item = manualOrderItemsArray[i];
      if ((!item.selectedVariation && item.variations.length === 0) && Number(item.mrp) > 0) {
        if (manualOrderItems.length == 1) {
          setMedicineErrors('Please select a variation for selected medicine');
        } else {
          setMedicineErrors('Please select a variation for all medicines');
        }
        return false;
      }
    }
    setMedicineErrors('');
    return true;
  };

  const checkAvailablePrescription = () => {
    if (!latestPrescription?.id) {
      setMedicineErrors('A prescription is needed to place an order');
      return false;
    }
    return true;
  };
  // const prescriptionList = useGetPrescriptionListQuery({id: patientManualDetails?.id });
  // const publishedPrescription = prescriptionList?.data?.data?.find(item => item.status === 'published');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'patientDetails':
        return <PatientDetails error={error} setError={setError} handleNext={handleNext} />;
      case 'orderDetails':
        return (
          <OrderDetails
            isNewMedicineAdded={isNewMedicineAdded}
            setIsNewMedicineAdded={setIsNewMedicineAdded}
            medicineErrors={medicineErrors}
            publishedPrescription={publishedPrescription}
            orderSummaryShipping={manualOrderSummary}
          />
        );

      case 'viewOrder':
        return (
          <ViewOrder
            orderStatus={orderStatus}
            patientsName={patientName}
            ordersId={orderId || newOrderId}
            publishedPrescription={publishedPrescription}
            notesData={reduxNotesData}
          />
        );
      default:
        return <PatientDetails />;
    }
  };

  const updateOrder = (oid, data) => {
    ApiClient.updateManualOrders(oid, data)
      .then((response) => {
        setCurrentOrderStatus(response.data.ehr_order_status);
        setNewOrderId(response.data.id);
        dispatch(setManualOrderId(response.data.id));
        dispatch(setManualOrderIdStr(response.data.order_id));
        dispatch(setManualOrderId(response.data));
        setActiveTab('viewOrder');
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('Error updating order: ', err);
      });
  };

  const buildInventoryData = (manualOrderItems, pincode, userId, latestPrescription) => {
    const result = {
      one_mg_skus: {},
      elevatenow_skus: {},
      user_id: userId,
      pincode: pincode, // Add pincode directly to the result
      elevatenow_skus_discount: {},
      latest_prescription: latestPrescription?.id,
    };

    manualOrderItems.forEach((item) => {
        if (item.vendor) {
            const selectedVendorId = item.vendor.id;
            // Find the matching product_price entry for the selected vendor
            const selectedProductPrice = item.product_price.find(
                (price) => price.vendor.id === selectedVendorId
            );

            if (selectedProductPrice) {
                const sku = selectedProductPrice.sku;
                // Categorize SKU based on the vendor name
                if (selectedProductPrice.vendor.vendor_name === "1MG") {
                    result.one_mg_skus[sku] = item.quantity;
                } else if (selectedProductPrice.vendor.vendor_name === "ElevateNow") {
                    result.elevatenow_skus[sku] = item.quantity;
                    result.elevatenow_skus_discount[sku] = parseFloat(item.discount/100);
                }
            }
        }
    });

    return result;
};

  const handleApiResponse = (response, manualOrderItems) => {
    const skus = response.data.skus;
    const skusKeys = Object.keys(skus);
  
    // Check if any SKU has quantity 0
    const invalidSku = skusKeys.find((sku) => skus[sku].quantity === 0);
  
    if (invalidSku) {
      // Find the index of the item with the invalid SKU
      const invalidItemIndex = manualOrderItems.findIndex((item) => {
        const selectedVendorId = item.vendor?.id; // Selected vendor ID
        const productSku = item.product_price.find(
          (price) => price.vendor?.id === selectedVendorId
        )?.sku;
        return productSku === invalidSku;
      });
  
      toast.error(`Item ${invalidItemIndex + 1} (sku: ${invalidSku}) is not in 1mg inventory`, {
        position: 'top-right',
        autoClose: 2000,
      })
      return false; // Return false to indicate invalid SKU found
    }
  
  
    // Update manualOrderItems with data from the API response
    const updatedManualOrderItems = manualOrderItems.map((item) => {
      const selectedVendorId = item.vendor?.id; // Selected vendor ID
      const productSku = item.product_price.find(
        (price) => price.vendor?.id === selectedVendorId
      )?.sku;
  
      // Find corresponding SKU in the API response
      const skuData = skus[productSku];
  
      if (skuData) {
        const { price, discounted_price } = skuData;
  
        return {
          ...item,
          item_mrp: parseFloat(price).toFixed(2), // Update item_mrp from API response
          item_amount: parseFloat(discounted_price).toFixed(2), // Update item_amount
          discount_percentage: 15, // Discount is fixed at 15% for 1mg
        };
      }
  
      // Return the item as-is if no SKU match
      return item;
    });
  
    // Recalculate total_amount
    const totalAmount = updatedManualOrderItems.reduce(
      (sum, item) => sum + parseFloat(item.item_amount) * item.quantity,
      0
    );
  
    // Update manualOrderItems and totalAmount in global state or context
    // If needed, pass updated values to other functions in handleNext
    manualOrderItems.length = 0; // Clear existing array
    updatedManualOrderItems.forEach((item) => manualOrderItems.push(item)); // Update in-place
  
    manualOrderSummary.total = totalAmount;
  
    return true; // Return true to indicate successful processing
  };

  function findSkuIndex(sku, combinedArray) {
    // Loop through the array and find the index of the matching SKU
    return combinedArray.findIndex(
        (item) => item.sku === sku || item.sku === `${sku}-en`
    );
}

const findVendorByName = (productPriceArray, vendorName) => {
  const vendorEntry = productPriceArray.find(
      (item) => item.vendor.vendor_name.toLowerCase() === vendorName.toLowerCase()
  );
  return vendorEntry ? vendorEntry.vendor : null;
};


  const handleNext = async () => {
      switch (activeTab) {
        case 'patientDetails':
          dispatch(setFromViewOrderToOrderDetail(false));
          //if (patientAddress?.id && patientManualDetails?.id) {
          if (patientAddress?.id && (patientManualDetails?.id || urlPatientId)) {
            setError('');
            setActiveTab('orderDetails');
          } else if (!patientAddress?.id) {
            setError("Please provide the patient's delivery address to continue with the order");
          } else if (!patientManualDetails?.id) {
            setError('Please select a patient to proceed');
          }
          break;
        case 'orderDetails':
          const usrId = patientManualDetails.id || urlPatientId;
          const pincode = patientAddress?.pincode; // Get pincode from state or props
          const inventoryData = buildInventoryData(manualOrderItems, pincode, usrId, latestPrescription);
          const response = await ApiClient.getInventory(inventoryData);
          const getInventoryData = response?.data?.data
          //Check if the response indicates success
          if (response?.data?.status_code !== 200 || !response?.data?.data) {
            throw new Error('API response indicates failure');
          }

          const combinedArray = [
            ...Object.entries(response?.data?.data?.elevatenow_skus).map(([sku, details]) => ({
              sku,
              ...details,
            })),
            ...Object.entries(response.data?.data?.skus).map(([sku, details]) => ({
              sku,
              ...details,
            })),
          ];

            if ((orderStatus === 'draft' || currentOrderStatus === 'draft') && checkManualOrderItems(manualOrderItems)) {
              const id = orderId || newOrderId;
              let manualOrderPost = {
                pincode: patientAddress.pincode,
                //source: 'Manual',
                prescription: latestPrescription?.id,
                user: patientManualDetails.id || urlPatientId,
                latest_shipping_address: patientAddress.id,
                shipping_address: '',
                order_items: manualOrderItems
                  .filter((item) => parseFloat(item.mrp) > 0) // Filter out items with mrp <= 0
                  .map((item) => {
                    const SKU = item.product_price[0].sku;
                    const indexx = findSkuIndex(SKU, combinedArray);
                    const mrp = indexx === -1 ? parseFloat(item?.mrp) : parseFloat(combinedArray[indexx]?.price); // Ensure mrp is a number
                    //const discount = parseFloat( ((combinedArray[indexx]?.price - combinedArray[indexx]?.discounted_price)/combinedArray[indexx]?.price )*100 ).toFixed(2) || 0; // Ensure discount is a number
                    const discount = indexx === -1 ? parseFloat(item?.discount) : parseFloat(
                      ((combinedArray[indexx]?.price - combinedArray[indexx]?.discounted_price) /
                        combinedArray[indexx]?.price) *
                        100
                    ).toFixed(2)
                    const vendorObj = findVendorByName(item.product_price, "1MG")
                    const vendorObjElevate = findVendorByName(item.product_price, "ElevateNow")
                    const qty = indexx === -1 ? item?.quantity : combinedArray[indexx]?.quantity //item.quantity,
                    const itemAmount = indexx === -1 ? parseFloat(item?.price).toFixed(2) : String(parseFloat(combinedArray[indexx]?.discounted_price).toFixed(2)) //parseFloat(mrp - (mrp * discount) / 100)?.toFixed(2),
                    //const vendorInfo = item.product_price.find(v => v.vendor);
                    return {
                      product_item_id: item.medicineId ? item.medicineId : item.id,
                      quantity: qty,
                      item_amount: itemAmount,
                      item_mrp: parseFloat(mrp).toFixed(2),
                      fulfilled_qty: qty,
                      discount_percentage: parseFloat(discount) || 15,
                      vendor: indexx === -1 ? (vendorObj?.id || vendorObjElevate?.id) : combinedArray[indexx]?.vendor?.id,//item?.vendor?.id || vendorInfo?.vendor?.id 
                    };
                  }),
                total_amount: parseFloat(getInventoryData?.payable_amount).toFixed(2) || parseFloat(manualOrderSummary?.total?.toFixed(2)),
                shipping_amount: parseFloat(manualOrderSummary?.shipping),
                payment_link: null,
                paid_amount: 0,
                discounted_amount: parseFloat((0).toFixed(2)),
              };
              //updateOrder(id, manualOrderPost)
              try {
                let response = await updateManualOrder({
                  id: id,
                  data: manualOrderPost,
                }).unwrap();
                if (response.status == 200 && response.data.id) {
                  setCurrentOrderStatus(response.data.ehr_order_status);
                  setNewOrderId(response.data.id);
                  dispatch(setManualOrderId(response.data.id));
                  dispatch(setManualOrderIdStr(response.data.order_id));
                  dispatch(setManualOrderId(response.data));
                  setActiveTab('viewOrder');
                }
              } catch (error) {
                console.error('Error updating order: ', error);
                toast.error('Error updating order. Please try again.', {
                  position: 'top-right',
                  autoClose: 2000,
                });
              }
            } 
            else if (
              manualOrderItems.length > 0 &&
              checkManualOrderItems(manualOrderItems) &&
              checkAvailablePrescription()
            ) {
              let manualOrderPost = {
                pincode: patientAddress.pincode,
                source: 'Manual',
                prescription: latestPrescription?.id,
                user: patientManualDetails.id || urlPatientId,
                latest_shipping_address: patientAddress.id,
                shipping_address: '',
                order_items: manualOrderItems
                  .filter((item) => parseFloat(item.mrp) > 0)//.filter((item) => parseFloat(item.mrp) > 0 && item.selectedVariation) // Filter out items with mrp <= 0
                  .map((item) => {
                    const SKU = item.product_price[0].sku;
                    const indexx = findSkuIndex(SKU, combinedArray);
                    const mrp = indexx === -1 ? parseFloat(item?.mrp) : parseFloat(combinedArray[indexx]?.price); // Ensure mrp is a number
                    //const discount = parseFloat( ((combinedArray[indexx]?.price - combinedArray[indexx]?.discounted_price)/combinedArray[indexx]?.price )*100 ).toFixed(2) || 0; // Ensure discount is a number
                    const discount = indexx === -1 ? parseFloat(item?.discount) : parseFloat(
                      ((combinedArray[indexx]?.price - combinedArray[indexx]?.discounted_price) /
                        combinedArray[indexx]?.price) *
                        100
                    ).toFixed(2)
                    const vendorObj = findVendorByName(item.product_price, "1MG")
                    const vendorObjElevate = findVendorByName(item.product_price, "ElevateNow")
                    const qty = indexx === -1 ? item?.quantity : combinedArray[indexx]?.quantity //item.quantity,
                    const itemAmount = indexx === -1 ? parseFloat(item?.price) : String(parseFloat(combinedArray[indexx]?.discounted_price).toFixed(2)) //parseFloat(mrp - (mrp * discount) / 100)?.toFixed(2),
                    return {
                      product_item_id: item.medicineId ? item.medicineId : item.id,
                      quantity: qty,
                      item_amount: itemAmount,
                      item_mrp: parseFloat(mrp).toFixed(2),
                      fulfilled_qty: qty,
                      discount_percentage: parseFloat(discount) || 15,
                      vendor: indexx === -1 ? (vendorObj?.id || vendorObjElevate?.id) : combinedArray[indexx]?.vendor?.id,//item?.vendor?.id || vendorInfo?.vendor?.id 
                    };
                  }),
                total_amount: parseFloat(getInventoryData?.payable_amount).toFixed(2) || parseFloat(manualOrderSummary?.total?.toFixed(2)),
                shipping_amount: manualOrderSummary?.shipping,
                payment_link: null,
                paid_amount: 0,
                discounted_amount: 0,
              };
              try {
                let response = await createManualOrder(manualOrderPost).unwrap();
                if (response.status == 200 && response.data.id) {
                  setCurrentOrderStatus(response.data.ehr_order_status);
                  setNewOrderId(response.data.id);
                  dispatch(setManualOrderId(response.data.id));
                  dispatch(setManualOrderIdStr(response.data.order_id));
                  dispatch(setManualOrderId(response.data));
                  setActiveTab('viewOrder');
                }
              } catch (error) {
                console.error('Error while creating order: ', error);
                toast.error('Something went wrong. Please try again.', {
                  position: 'top-right',
                  autoClose: 2000,
                });
              }
            }

          break;
        default:
          break;
      }
  };

  const handlePrevious = () => {
    switch (activeTab) {
      case 'viewOrder':
        dispatch(setFromViewOrderToOrderDetail(true));
        // if (window.confirm('You may lose manually added medicine while going back. Do you wish to continue?')) {
        //   setActiveTab('orderDetails');
        // }
        setActiveTab('orderDetails');
        break;
      case 'orderDetails':
        dispatch(setFromViewOrderToOrderDetail(false));
        setActiveTab('patientDetails');
        break;
      default:
        dispatch(setFromViewOrderToOrderDetail(false));
        break;
    }
  };

  useEffect(() => {
    if (!openDetailModal) searchOrders();
  }, [searchStr, currentPage, openDetailModal]);

  const searchOrders = () => {
    setIsLoading(true);
    let params = { page: currentPage, search_str: searchStr };
    //let params = { page: orderCurentPage === 0 ? currentPage : orderCurentPage, search_str: searchStr };
    ApiClient.searchOrders(params)
      .then((res) => {
        setIsLoading(false);
        if (value.current === params.search_str) {
          setTotalPages(res.headers['total-pages']);
          setOrderList(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('getOrders', err.response);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className='w-100'>
        <CoreInputBox
          placeholder='Search a Order by Patient name or Order ID'
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const downloadOrder = () => {
    const id = typeof manualOrderId === 'object' ? manualOrderId.id : manualOrderId;
    const ordId = id ? id : urlOrderId;
    ApiClient.downloadOrder(ordId, { responseType: 'arraybuffer' })
    .then((res) => {
      const imageUrl = res.data.image_url;
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `order_${ordId}.png`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
      // Open image in a new tab
      const newTab = window.open(imageUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open image in a new tab.");
      }
    })
    .catch((err) => {
      console.log("downloadOrder error: ", err);
    });
  };

  const renderHeader = () => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='shopping_cart' title='Orders' searchBox={renderSearchBox()} />
      </div>
    );
  };

  const showOrders = () => {
    const textColor1 =
      activeTab == 'patientDetails' || activeTab == 'orderDetails' || activeTab == 'viewOrder' ? '#243b86' : '';
    const textColor2 = activeTab == 'orderDetails' || activeTab == 'viewOrder' ? '#243b86' : '';
    const textColor3 = activeTab == 'viewOrder' ? '#243b86' : '';
    return (
      <>
        <div className='articleHeader stickyHeader'>
          {renderHeader()}
          <div className='hrLine mb-3' />
          {/* <RowLinks data={headerData} /> */}
        </div>

        <div className='px-4 wrapperContent order-form'>
          <div className='tabs'>
            <div className={`tab ${activeTab === 'patientDetails' ? 'active' : ''}`} style={{ color: textColor1 }}>
              <img className='img-icons' src={patientDetails} alt='patientDetails' />
              Patient Details
            </div>
            <div className={`tab ${activeTab === 'orderDetails' ? 'active' : ''}`} style={{ color: textColor2 }}>
              {activeTab == 'orderDetails' || activeTab == 'viewOrder' ? (
                <img className='img-icons' src={orderDetailsBlue} alt='orderDetails active' />
              ) : (
                <img className='img-icons' src={orderDetails} alt='orderDetails' />
              )}
              Order Details
            </div>
            <div className={`tab ${activeTab === 'viewOrder' ? 'active' : ''}`} style={{ color: textColor3 }}>
              {activeTab == 'viewOrder' ? (
                <img className='img-icons' src={viewOrderBlue} alt='viewOrder active' />
              ) : (
                <img className='img-icons' src={viewOrder} alt='viewOrder' />
              )}
              View Order
            </div>
          </div>
          <div className='tab-content'>{renderTabContent()}</div>

          <div className='hrLine mb-3 mt-3' />
          <div className='form-footer'>
            {/* {activeTab !== "patientDetails" && isNewMedicineAdded && (
              <button
                className="save-order-btn"
                onClick={() => navigate("/orders")}
              >
                Save Order
              </button>
            )} */}
            {activeTab === 'patientDetails' && (
              <button className={prescriptionData.length === 0 ? 'dis-next-btn' : 'next-btn'} onClick={handleNext} disabled={prescriptionData.length === 0}>
                Next
              </button>
            )}
            {activeTab === 'orderDetails' && (
              <>
                <button className='previous-btn' onClick={handlePrevious}>
                  Previous
                </button>
                <button className='next-btn' onClick={handleNext}>
                  Next
                </button>
              </>
            )}
            {activeTab === 'viewOrder' && (
              <>
                <button className='previous-btn' onClick={handlePrevious}>
                  Previous
                </button>
                <button
                  style={{
                    backgroundColor: '#fff',
                    color: '#243B86',
                    border: '1px solid #243B86',
                    borderRadius: '8px',
                    padding: '10px',
                    marginRight: '10px',
                    fontWeight: '600',
                  }}
                  onClick={downloadOrder}
                >
                  <img style={{ paddingRight: '10px' }} src={downloadIcon} alt='Download Icon' />
                  Download
                </button>
                <button
                  className='save-order-btn'
                  style={{
                    background: '#243B86',
                    color: '#FFFFFF',
                    borderRadius: '8px',
                    padding: '10px',
                    fontWeight: '600',
                  }}
                  onClick={() => navigate('/orders')}
                >
                  Save Order
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      <Loader show={isLoading} />
      {showOrders()}
      <ToastContainer toastStyle={{ backgroundColor: '#EBF8E7' }} />
    </div>
  );
}

export default AddOrder;
